import { formatDate } from '../components/formatDate';

export const handleDate = (date) => {
  const formattedDate = `${formatDate(date).month} ${formatDate(date).date}, ${formatDate(date).year}`;
  return `${formattedDate}`;
};

export const prettyDate = (date) => {
  if (!date) return '';
  const formatted = formatDate(date);
  return `${formatted.day} ${formatted.date} ${formatted.monthLong}, ${formatted.hour}`;
};
