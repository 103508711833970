import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { formatDate } from '../components/formatDate';
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import { PastPublicPrograms } from '../components/pastPublicPrograms';
dayjs.extend(utc)
dayjs.extend(timezone)
import ImageFadeIn from "react-image-fade-in"

const PublicProgram = ({ data }) => {
  const [futurePrograms, setFuturePrograms] = useState([]);
  const { publicProgram } = data;

  useEffect(() => {
    const now = new Date();
    const future = [];

    data.allDatoCmsSinglePublicProgram.edges.map(({ node: program }) => {
      const startDate = new Date(program?.event[0]?.performanceDate);
      if (startDate > now) {
        future.push(program);
      }

      return null;
    });

    setFuturePrograms(future);
  }, []);

  return (
    <Layout bgcolor={publicProgram.backgroundColor} wrapperClass="public-program" accentColor={publicProgram.accentColor}>
      <HelmetDatoCms seo={publicProgram.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{publicProgram.title}</h1>
        </div>
        <div className="main-image col">
				<Img fluid={publicProgram.featuredImage.fluid} className="featured-title-image" />  
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: publicProgram.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: publicProgram.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={publicProgram.accentColor} />
          </div>
        </div>
      </div>
      {futurePrograms.length > 0 && (
        <div className="programs-upcoming container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Upcoming</span>
          </div>
          <div className="col-sm-12 col-xs-6 col">
            {futurePrograms.map((program) => {

              return (
                <div className="itr image-text-row spacing-0" key={program.id}>
                  <div className="image-text-row-image-wrapper">
                    <Link to={`/public-program/${program.slug}`}>
											<ImageFadeIn
												src={program.featuredImage.url}
												alt={program.featuredImage.alt}
											/>   
                    </Link>
                  </div>
                  <div className="image-text-row-text-wrapper">
                    <Link to={`/public-program/${program.slug}`}>
                      <h3
                        className="sans--medium"
                      // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: program.formattedTitle,
                        }}
                      />
                    </Link>
                    <div className="program-details">
                      <b>{program.publicProgramType.title}</b>
                      <br />
											{dayjs(program.event[0]?.performanceDate).format("dddd D MMMM YYYY, h:mma")}
                      <br />
                      {program.location.title}
                    </div>
                    {program.excerpt !== '' && (
                      <div
                        className="excerpt content"
                      // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: program.excerpt,
                        }}
                      />
                    )}
                    <Link to={`/public-program/${program.slug}`} className="read-more">
                      Read more
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col spacing-0">
          <DividerBlock accentColor={publicProgram.accentColor} />
        </div>
      </div>
      <PastPublicPrograms />
      <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-8 col">
        <DividerBlock accentColor={publicProgram.accentColor.hex} className="" />
      </div>
    </Layout>
  );
};

PublicProgram.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default PublicProgram;

export const query = graphql`
  query PublicProgramQuery {
    publicProgram: datoCmsPublicProgram {
      accentColor {
        hex
      }
      backgroundColor
      introduction
      title
      featuredImage {
        alt
				fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsSinglePublicProgram {
      edges {
        node {
          slug
          formattedTitle
          id
          publicProgramType {
            title
          }
          event {
            performanceDate
          }
          location {
            title
          }
          excerpt
          featuredImage {
            alt
						url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
          }
        }
      }
    }
  }
`;
