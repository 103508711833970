import React, { useState, useRef, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { handleDate } from '../helpers/handleDate';
import FilterArrow from '../images/filter-arrow.inline.svg';

import {
  defaultFilterValue,
  filterItemByYear,
  getUniqueYearList,
  useFilters,
  loadMoreVisibility,
  handleLoadMore,
} from '../helpers/filtering';
import ImageFadeIn from "react-image-fade-in"

export const PastPublicPrograms = () => {
  const data = useStaticQuery(graphql`
    query PastProgramQuery {
      pastPrograms: allDatoCmsSinglePublicProgram(
        sort: { fields: event___performanceDate, order: DESC }
        filter: { programStatus: { in: ["", "PAST"] } }
      ) {
        edges {
          node {
            id
            slug
            model {
              name
            }
            formattedTitle
            featuredImage {
							url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
              alt
            }
            event {
              performanceDate
            }
            location {
              title
            }
          }
        }
      }
    }
  `);

  const slugPrefix = 'public-program';
  const sideBarText = 'Past Programs';

  const items = data.pastPrograms.edges;
  const increment = 16;
  const years = getUniqueYearList(items);
  const yearRef = useRef(null);
  const galleries = [];
  const galleryRef = useRef(null);

  const [activeItems, setActiveItems] = useState(items);
  const [visibleLimit, setVisibleLimit] = useState(increment);

  // filtering
  const [filters, setFilters] = useState({
    year: defaultFilterValue,
  });

  const handleFilterItems = (exhibitions) => {
    const filteredExhibitions = exhibitions
      .filter((exhibition) => filterItemByYear(filters.year, exhibition));
    setVisibleLimit(increment);
    setActiveItems(filteredExhibitions);
  };

  const handleFilters = () => {
    setFilters({
      year: yearRef.current.value || defaultFilterValue,
    });
  };

  useFilters(filters, handleFilterItems, items);

  useEffect(() => {
    handleFilters();
  }, []);

  return (
    <>
      {activeItems.length > 0 && (
        <div className="programs-past container row">
          <div className="col-sm-14 col-xs-6 col filter-row">
            <div className="filter-name">{sideBarText}</div>
            <div className="filters">
              {years.length > 0 && (
                <label className="filter" htmlFor="past-year-filter">
                  <span className="filter-text">Year:</span>
                  <div className="custom-filter-select">
                    <span>{filters.year}</span>
                    <select
                      ref={yearRef}
                      name="year"
                      id="past-year-filter"
                      onChange={(e) => {
                        handleFilters(e);
                      }}
                    >
                      <option value={defaultFilterValue}>
                        {defaultFilterValue}
                      </option>
                      {years.map((yearValue) => (
                        <option key={yearValue} value={yearValue}>
                          {yearValue}
                        </option>
                      ))}
                    </select>
                    <FilterArrow />
                  </div>
                </label>
              )}
              {galleries.length > 0 && (
                <label className="filter" htmlFor="past-gallery-filter">
                  <div className="filter-text">Gallery:</div>
                  <div className="custom-filter-select">
                    <span className="filter-selected">{filters.gallery}</span>
                    <select
                      ref={galleryRef}
                      id="past-gallery-filter"
                      onChange={() => {
                        handleFilters();
                      }}
                    >
                      <option value={defaultFilterValue}>
                        {defaultFilterValue}
                      </option>
                      {galleries.map((val) => (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                    <FilterArrow />
                  </div>
                </label>
              )}
            </div>
          </div>
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text filter">{sideBarText}</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {activeItems.map(({ node: item }, i) => (
              <div
                className="tile exhibition-tile-wrapper col"
                key={item.id}
                style={{ display: `${loadMoreVisibility(i, visibleLimit)}` }}
              >
                <Link to={`/${slugPrefix}/${item.slug}`} className="tile-link">
                  {item.featuredImage && ( 
										<ImageFadeIn
											src={item.featuredImage.url}
											alt={item.featuredImage.alt}
											className="tile-image"
										/>
                  )}
                  <div className="exhibition-tile-text-wrapper tile-text-wrapper">
                    <h3
                      className="sans--regular exhibition-title"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.formattedTitle,
                      }}
                    />
                    {item.locations && item.locations[0] && (
                      <p className="exhibition-location">
                        {item.publicProgramType.title}
                      </p>
                    )}
                    <p className="exhibition-dates">
                      {item.event
                        && item.event.length > 0
                        && `${handleDate(item.event[0].performanceDate)}`}
                      <br />
                      {item.location && item.location.title}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="container col row">
            <div className="offset-sm-2 col-sm-12 col-xs-6 col row spacing-0">
              {activeItems.length > visibleLimit && (
                <button
                  type="button"
                  onClick={() => {
                    handleLoadMore(setVisibleLimit, visibleLimit, increment);
                  }}
                  aria-label="Load more"
                  className="btn show-more"
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
